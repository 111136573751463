import { BasicStore, httpClient, httpClient2, httpClient4 } from '@plarin/core';
import {
  AvailableAdsProps,
  FixBidSaveActionProps,
  GridApi,
  MENU_ITEM_LABEL,
  StatusEnumVK,
  StrategyBidSaveActionProps,
  StrategySaveAction,
  TDateOption,
  TRANSLATION,
  WhatIsEditedInStrategy,
} from '@plarin/inputs';
import {
  ManageVkTabNameEnum,
  captureError,
  getNumeral,
  NotificationErrorTitle,
  NotificationTitle,
  objTypes,
  TabNameToIds,
  TabNameToReq,
  RespToTabId,
  sendMetricGoal,
  isUrlProd,
  isUrlPreProd,
} from '@plarin/utils';
import { IRowNode } from 'ag-grid-community';
import { formatISO } from 'date-fns';
import { action, makeObservable, observable, runInAction, transaction } from 'mobx';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import React from 'react';
import { toast } from 'react-toastify';
import { DateOptions } from '../../types/common-types';
import {
  AdsSmallPreviewResp,
  AvailableAdsItemResp,
  BidResp,
  ChangeStatusIn,
  Dictionary,
  FastStatRequestTypes,
  FastStatResponse,
  FilterState,
  IssuesType,
  MtRespError,
  MTStatus,
  NameResp,
  ProjectionResp,
  ScheduleReq,
  AccountAdditionalItemResp,
  MetricRecord,
} from '../../types/manage-vk/types';
import { TMetric, TMetricGroup, TObjType } from '../../types/metrics';
import { TWsDictionary } from '../../types/profile/types';
import { mainTabData, MTManageVkStatusEnum } from '../dictionary/manage-vk';
import {
  DownloadExcelError,
  FAST_STAT,
  FAST_STAT_IDS,
  ignoredGroupingMetrics,
  PREVIEW,
  STATUS,
  STORAGE_ITEM,
} from '../utils/constants';
import { downloadFile } from '../utils/downloadFIle';
import { parseDate72, parseFastStat, parseFastStat72, parseTime } from '../utils/fast-stat-parsing';
import { grouping } from '../utils/grouping';
import {
  formatDateToReqString,
  getAdsCampaignSchedule,
  getEditedStrategyItems,
  getStrategySiblingsData,
  getStrategySiblingsNewData,
  parseStatistics,
  parseStringToCamelCase,
  parseToData,
} from '../utils/manage-vk';

type DOptions = Pick<TDateOption, 'endDate' | 'startDate'> | string;

export type TManageVKData = Record<string, any>;

type TManageVKDataRequest = {
  cols?: string[];
  client_ids?: number[];
  ad_plan_ids: number[];
  ad_group_ids?: number[];
  ad_ids?: number[];
  status?: number[];
  relative_period?: string;
  date_from?: string;
  date_to?: string;
};

export type TMetricsForGrouping = Record<TObjType, TMetric[]>;

const checkFast72 = (): boolean => {
  const checkedMetricsVK = localStorage.getItem('checkedMetricsVK');
  return !!checkedMetricsVK && JSON.parse(checkedMetricsVK).includes('satistics_fast72');
};

export class ManageVKStore extends BasicStore {
  filter: FilterState = { inputValue: '' };
  data?: TManageVKData[] = [];
  accountIds: number[] = [];
  tableLoading: boolean = false;
  metricsVK?: TMetricGroup[];
  drawerAction?: () => void;
  manageVKTabs: number[] = [3, 5];
  dictionary?: Dictionary;
  drawerVisible: boolean = false;
  filterValue?: string;
  filterStatuses?: StatusEnumVK[] = [StatusEnumVK.active, StatusEnumVK.blocked];
  fast72Checked: boolean = checkFast72();
  errorTable?: boolean = false;
  metricsFilterValue: string = '';
  dateOptions: DOptions = {} as DOptions;
  date: DateOptions = {
    storeLabel: MENU_ITEM_LABEL.All_TIME,
    storeEndDate: Date.now(),
    storeStartDate: +new Date('2012, 01, 01'),
  } as DateOptions;
  deleteAction?: () => void;
  currentTab?: ManageVkTabNameEnum;
  configRequestStat: Partial<TManageVKDataRequest> = {};
  isLoading: boolean = true;
  metricsForGrouping: TMetricsForGrouping = {} as TMetricsForGrouping;
  isGroupDestroyed: boolean = false;
  selectedAdIds: number[] = [];
  selectedAdPlansIds: number[] = [];
  selectedClientsIds: number[] = [];
  selectedAdGroupsIds: number[] = [];

  constructor() {
    super();
    makeObservable<this>(this, {
      filter: observable,
      filterStatuses: observable,
      tableLoading: observable,
      drawerVisible: observable,
      metricsVK: observable,
      drawerAction: observable,
      manageVKTabs: observable,
      dictionary: observable,
      filterValue: observable,
      fast72Checked: observable,
      errorTable: observable,
      metricsFilterValue: observable,
      dateOptions: observable,
      date: observable,
      isLoading: observable,
      data: observable.shallow,
      deleteAction: observable,
      currentTab: observable,
      configRequestStat: observable,
      accountIds: observable,
      metricsForGrouping: observable,
      isGroupDestroyed: observable,
      selectedAdIds: observable,
      selectedAdPlansIds: observable,
      selectedClientsIds: observable,
      selectedAdGroupsIds: observable,
      setSelectedIds: action,
    });
  }

  setIsGroupDestroyed = (value: boolean) => runInAction(() => (this.isGroupDestroyed = value));

  setLoadingCells = (cellKey: number[], cellLoadingName: string) =>
    runInAction(
      () => (this.data = this.data!.map(el => (cellKey.includes(+el.key) ? { ...el, cellLoadingName } : el))),
    );

  getSelectedIds = (tabName: ManageVkTabNameEnum) => {
    switch (tabName) {
      case ManageVkTabNameEnum.ACCOUNTS:
        return this.selectedClientsIds;
      case ManageVkTabNameEnum.ADPLANS:
        return this.selectedAdPlansIds;
      case ManageVkTabNameEnum.ADGROUPS:
        return this.selectedAdGroupsIds;
      case ManageVkTabNameEnum.ADS:
        return this.selectedAdIds;
      default:
        return [];
    }
  };

  setSelectedIds = (ids: number[], tabName: ManageVkTabNameEnum) => {
    switch (tabName) {
      case ManageVkTabNameEnum.ACCOUNTS:
        this.selectedClientsIds = ids;
        break;
      case ManageVkTabNameEnum.ADPLANS:
        this.selectedAdPlansIds = ids;
        break;
      case ManageVkTabNameEnum.ADGROUPS:
        this.selectedAdGroupsIds = ids;
        break;
      case ManageVkTabNameEnum.ADS:
        this.selectedAdIds = ids;
        break;
    }
  };

  setCurrentTab = (tabName: ManageVkTabNameEnum) => runInAction(() => (this.currentTab = tabName));

  setDeleteAction = (action?: () => void) => {
    runInAction(() => (this.deleteAction = action));
  };

  private startLoading = () => runInAction(() => (this.tableLoading = true));
  private stopLoading = () => runInAction(() => (this.tableLoading = false));

  setTableLoading = (e: boolean) => runInAction(() => (this.tableLoading = e));

  setDateOption = (dateOption: TDateOption) => {
    const { label, endDate, startDate } = dateOption;
    let result: DOptions = '';
    if (label === 'За сегодня') {
      result = 'today';
    }
    if (label === 'За вчера') {
      result = 'yesterday';
    }
    if (label === 'Текущая неделя') {
      result = 'this_week';
    }
    if (label === 'Прошлая неделя') {
      result = 'last_week';
    }
    if (label === 'Текущий месяц') {
      result = 'current_month';
    }
    if (label === 'Прошлый месяц') {
      result = 'last_month';
    }
    if (label === 'За определенный период') {
      result = { startDate, endDate };
    }
    if (label === 'За все время') {
      result = {} as DOptions;
    }
    runInAction(() => (this.date = { storeLabel: label, storeStartDate: startDate, storeEndDate: endDate }));
    runInAction(() => (this.dateOptions = result));
  };

  setFilterStatuses = (newStatus: StatusEnumVK[]) => runInAction(() => (this.filterStatuses = newStatus));

  setFast72Checked = (value: boolean) => runInAction(() => (this.fast72Checked = value));

  toggleDrawer = () => runInAction(() => (this.drawerVisible = !this.drawerVisible));
  closeDrawer = () => runInAction(() => (this.drawerVisible = false));
  setDrawerAction = (action?: () => void) => runInAction(() => (this.drawerAction = action));

  // ============filter=============
  setFilterValue = (value: string) => runInAction(() => (this.filterValue = value));
  setMetricFilterValue = (value: string) => runInAction(() => (this.metricsFilterValue = value));
  // ==========endFilter============

  checkedTabs = (id: number) => {
    if (this.manageVKTabs.includes(id)) {
      runInAction(() => (this.manageVKTabs = this.manageVKTabs.filter(el => el !== id)));
    } else {
      runInAction(() => (this.manageVKTabs = [...this.manageVKTabs, id]));
    }
  };

  getMetricsVK = async () => {
    const checkedMetricsVK = JSON.parse(localStorage.getItem(STORAGE_ITEM.localStorage.METRICSVK) ?? '[]');

    await this.execRequest<TMetricGroup[]>(httpClient2.get('/api/v1/core/flat_metrics'))
      .then(res => {
        const dictionary = {} as Dictionary;
        res.forEach(group =>
          group.fields.forEach(metric => (dictionary[parseStringToCamelCase(metric.path)] = metric.type)),
        );

        const result = res.map(group => ({
          ...group,
          selected: false,
          fields: group.fields.map(metric => ({
            ...metric,
            checked: checkedMetricsVK.length ? checkedMetricsVK.includes(metric.path) : false,
          })),
        }));

        runInAction(() => (this.dictionary = dictionary));

        const metricsForGrouping: TMetricsForGrouping = {
          account: [],
          ad_plan: [],
          campaign: [],
          banner: [],
        };

        const groupMetricsArr = Object.keys(metricsForGrouping) as TObjType[];

        groupMetricsArr.forEach(groupingName => {
          result.forEach(group => {
            group.fields.forEach(metric => {
              if (
                metric.grouping &&
                metric.obj_types.includes(groupingName as TObjType) &&
                !ignoredGroupingMetrics[groupingName].includes(metric.path)
              ) {
                metricsForGrouping[groupingName].push({ ...metric, checked: false });
              }
            });
          });
        });
        runInAction(() => (this.metricsForGrouping = metricsForGrouping));
        this.setMetrics(
          result.map(group => ({
            ...group,
            fields: group.fields.map(metric => ({
              ...metric,
              checked: checkedMetricsVK.length ? checkedMetricsVK.includes(metric.path) : false,
            })),
          })),
        );
      })
      .catch(err => {
        this.onLoadError(err);
        runInAction(() => (this.metricsVK = []));
        runInAction(() => (this.metricsForGrouping = { campaign: [], ad_plan: [], banner: [], account: [] }));
      });
  };

  setMetrics = (metrics: TMetricGroup[]) => runInAction(() => (this.metricsVK = metrics));

  getFastStat = (
    gridApi: GridApi,
    nodes: IRowNode[],
    fast72checked: boolean,
    type: FastStatRequestTypes,
    idType: typeof FAST_STAT_IDS[keyof typeof FAST_STAT_IDS],
  ) => {
    const cols: string[] = [FAST_STAT_IDS[type], FAST_STAT.SatisticsFast1];

    const fastNodesFirstRender = nodes.filter(el => el.data.fastStat === 0);
    const fastKeys = nodes.map(({ data }) => data.key);
    const fastNodes = nodes.filter(el => el.data.fastStat);

    if (!fastNodesFirstRender.length && !fastNodes.length) return;
    if (fastNodesFirstRender) {
      fast72checked && cols.push(FAST_STAT.SatisticsFast72);

      setTimeout(() => {
        gridApi.applyTransaction({
          update: fastNodesFirstRender.map(({ data }) => ({
            ...data,
            [FAST_STAT.Faststat]: 'loading',
            [FAST_STAT.Faststat72]: fast72checked && 'loading',
          })),
        });
      });
    }

    const renderFastNodes = fastNodesFirstRender.length ? fastNodesFirstRender : fastNodes;

    this.execRequest<FastStatResponse>(
      httpClient2.post('/api/v1/core/fast-stat', { network: 'mt', type, ids: fastKeys, cols }),
    )
      .then(res => {
        const resFast: { [p: string]: any } = res[type].reduce((acc, el) => {
          const satisticsFast =
            el[FAST_STAT.SatisticsFast1] &&
            el[FAST_STAT.SatisticsFast1]
              .map((stat: { t: string }) => {
                return {
                  ...stat,
                  t: parseTime(stat.t),
                };
              })
              .sort((a: { t: number }, b: { t: number }) => a.t - b.t);

          const satisticsFast72 =
            el[FAST_STAT.SatisticsFast72] &&
            el[FAST_STAT.SatisticsFast72]
              ?.map((stat: { d: string }) => {
                return {
                  ...stat,
                  d: parseDate72(stat.d),
                };
              })
              .sort((a: { d: number }, b: { d: number }) => a.d - b.d);

          // @ts-ignore
          acc[el[idType]] = {
            [FAST_STAT.SatisticsFast1]:
              satisticsFast && parseFastStat(satisticsFast, Date.parse(el[FAST_STAT.DateFrom] || '') / 1000),
            [FAST_STAT.SatisticsFast72]:
              satisticsFast72 && parseFastStat72(satisticsFast72, parseDate72(el[FAST_STAT.DateFrom72] || '')),
          };

          return acc;
        }, {});

        gridApi.applyTransaction({
          update: renderFastNodes.map(({ data }) => ({
            ...data,
            [FAST_STAT.Faststat]: resFast[data.key][FAST_STAT.SatisticsFast1],
            [FAST_STAT.Faststat72]: resFast[data.key][FAST_STAT.SatisticsFast72],
          })),
        });

        this.data?.forEach(el => {
          if (resFast[el.key]) {
            el[FAST_STAT.Faststat] = resFast[el.key][FAST_STAT.SatisticsFast1];
            el[FAST_STAT.Faststat72] = resFast[el.key][FAST_STAT.SatisticsFast72];
          }
        });
      })
      .catch(() => {
        gridApi.applyTransaction({
          update: renderFastNodes.map(({ data }) => ({
            ...data,
            [FAST_STAT.Faststat]: [],
            [FAST_STAT.Faststat72]: [],
          })),
        });
        this.data?.forEach(el => {
          if (renderFastNodes.includes(el.key)) {
            el[FAST_STAT.Faststat] = [];
            el[FAST_STAT.Faststat72] = [];
          }
        });
      });
  };

  getProjection = async (id: string | number | [], allPoints: boolean): Promise<ProjectionResp> => {
    return this.execRequest<ProjectionResp>(
      httpClient2.post('/api/v1/connect/mt/projection', { campaign_id: +id, all_points: allPoints ? '1' : '0' }),
    )
      .then(res => {
        const result = res;

        result.point &&
          !result.chart_data.some(data => data.price === result.point.price) &&
          result.chart_data?.push(result.point);

        result.chart_data.sort((a, b) => a.price - b.price);

        const max = {
          uniqs: result.chart_data?.slice(-1)[0].uniqs,
          share: result.chart_data?.slice(-1)[0].share,
          price: result.max,
        };
        !result.chart_data.some(data => data.price === max.price) && result.chart_data?.push(max);
        const min = {
          uniqs: result.chart_data?.slice()[0].uniqs,
          share: result.chart_data?.slice()[0].share,
          price: result.min,
        };
        !result.chart_data.some(data => data.price === min.price) && result.chart_data?.unshift(min);

        return result;
      })
      .catch(err => {
        throw new Error(err);
      });
  };

  getAvailableAds = async ({ ids, type, setIsBlockedBudget, setAdsArr }: AvailableAdsProps) => {
    try {
      const response = await this.execRequest<AvailableAdsItemResp[]>(
        httpClient2.post('/api/v1/core/mt/available_ads', { ids, type }),
      );
      setAdsArr(response.map(i => i.available_ads));
    } catch (error) {
      setAdsArr([]);
    } finally {
      setIsBlockedBudget(false);
    }
  };

  editStrategyBid = async ({
    requestData,
    itemsType,
    commonBudgetOptimizationLevel,
    newBidsData,
    gridApi,
    whatIsEdited,
  }: StrategySaveAction) => {
    const { keysOfSiblingsToEdit: keysOfAllSiblings, parentsIds: allParentsIds } = getStrategySiblingsData(
      commonBudgetOptimizationLevel === 'adPlan' ? 'adPlanId' : 'campaignId',
      requestData,
      gridApi,
    );

    // устанавливаем лоадер в редактируемых строчках таблицы
    gridApi.applyTransaction({
      update: keysOfAllSiblings.map(el => ({ ...gridApi.getRowNode(el)?.data, cellLoadingName: 'bid' })),
    });
    gridApi.redrawRows();

    try {
      const response = await this.execRequest<BidResp>(
        httpClient2.post('/api/v1/connect/mt/bid', { data: requestData }),
      );
      // Отправляем конверсию в Яндекс.Метрику
      whatIsEdited === WhatIsEditedInStrategy.bid
        ? sendMetricGoal('usage_vk_change_bid', 'manage/vk')
        : sendMetricGoal('usage_vk_change_budget', 'manage/vk');
      // эти рекламные объекты не отредактировались (такое может изредка происходить, если мы редактируем кампании, принадлежащие разным рекламным кабинетам)
      const uneditedIds = response.errors?.map(i => i.obj_id);

      // кладём сюда данные по тем рекламным объектам, которые успешно отредактировались
      const editedItemsData = uneditedIds?.length
        ? // в этом случае отредактировалось либо не всё что мы хотели, либо вообще ничего
          requestData.filter(item => !uneditedIds.includes(item.obj_id))
        : // в этом случае всё успешно отредактировалось
          requestData;

      let keysOfSiblingsToEdit, parentsIds;

      // уточняем данные, которые нужно обновить в таблице
      if (uneditedIds?.length) {
        // этот блок выполнится, если только часть рекламных объектов была успешно отредактирована
        const { keysOfSiblingsToEdit: newKeys, parentsIds: someParentsIds } = getStrategySiblingsData(
          commonBudgetOptimizationLevel === 'adPlan' ? 'adPlanId' : 'campaignId',
          editedItemsData,
          gridApi,
        );

        keysOfSiblingsToEdit = newKeys;
        parentsIds = someParentsIds;
      } else {
        // это выполнится, если все рекламные объекты были успешно отредактированы
        keysOfSiblingsToEdit = keysOfAllSiblings;
        parentsIds = allParentsIds;
      }

      if (response.command_results?.length && editedItemsData.length) {
        if (itemsType === 'ad_plan') {
          // если редактируем адпланы, то просто подставляем новые данные в те адпланы, которые были отредактированы

          const editedAdPlans: Record<
            string,
            {
              maxPrice: number | '';
              adPlanDaily: number | '';
              adPlanLifetime: number | '';
              adPlanStart: string | null;
              adPlanStop: string | null;
            }
          > = {};

          editedItemsData.forEach(
            adPlan =>
              (editedAdPlans[adPlan.obj_id] = {
                maxPrice: adPlan.max_price || '',
                adPlanDaily: adPlan.budget_limit_day || '',
                adPlanLifetime: adPlan.budget_limit || '',
                adPlanStart: formatDateToReqString(adPlan.date_start),
                adPlanStop: formatDateToReqString(adPlan.date_end),
              }),
          );

          const keysToEdit = new Set(Object.keys(editedAdPlans));

          const rowsToUpdate = [...keysToEdit].map(el => ({
            ...gridApi.getRowNode(el.toString())?.data,
            ...editedAdPlans[el.toString()],
          }));

          gridApi.applyTransaction({ update: rowsToUpdate });
        }

        if (itemsType === 'campaign' || itemsType === 'banner') {
          // Если оптимизация выключена (бюджетные ограничения определяются на уровне групп), то все данные записываем только в campaign, сиблингов по адплану не трогаем
          // если оптимизация на уровне адпланов, то bugetDaily, budgetLifetime, maxPrice перезаписываем всем сиблингам (группы или объявления) с тем же adPlanId

          const isOptimized = commonBudgetOptimizationLevel === 'adPlan';
          const parentIdName = commonBudgetOptimizationLevel === 'adPlan' ? 'adPlanId' : 'campaignId'; // на этом уровне определяются бюджетные ограничения
          const itemIdName = itemsType === 'campaign' ? 'campaignId' : 'adId';
          const hasNoSiblings = itemsType === 'campaign' && commonBudgetOptimizationLevel === 'campaign';

          const editedItems = getEditedStrategyItems(editedItemsData, isOptimized);

          // отсюда мы будем получать данные сиблингов, которые непосредственно не редактировались, но которые нужно обновить, т.к. настройки задаются уровнем выше (для объявлений - всегда уровнем выше, а для групп - если оптимизация на уровне адплана)
          const siblingsNewData = hasNoSiblings
            ? {} // нет сиблингов, ничего не вычисляем
            : getStrategySiblingsNewData(newBidsData, isOptimized, parentIdName, parentsIds);

          const setOfEditedItemsIds = new Set(Object.keys(editedItems));
          const setOfSiblingKeys = new Set(keysOfSiblingsToEdit);

          // объект для обновления дат работы ГРУПП у сиблингов отредактированных объявлений
          const siblingsSchedule = getAdsCampaignSchedule({
            commonBudgetOptimizationLevel,
            itemsType,
            newBidsData,
            storeData: this.data,
            parentIdsSet: parentsIds,
          });

          const data = this.data?.map(el =>
            setOfSiblingKeys.has(el.key)
              ? setOfEditedItemsIds.has(el[itemIdName])
                ? // этот элемент - непосредственно редактируемая сущность, и нужно обновить все данные, включая даты работы группы
                  {
                    ...el,
                    ...editedItems[el[itemIdName]],
                  }
                : // Если мы здесь, то: 1) оптимизация бюджета выключена и бюджетные ограничения определены на уровне группы 2) этот элемент - сиблинг одной из редактируемых сущностей, у него даты работы группы не трогаем
                  {
                    ...el,
                    ...siblingsNewData[el[parentIdName]],
                    ...siblingsSchedule[isOptimized ? el.key : el[parentIdName]], // обновляем расписание группы для  баннеров. У остальных сущностей siblingsSchedule - пустой объект
                  }
              : {
                  ...el, // этот элемент не редактируем, возвращаем в неизменном виде
                },
          );

          gridApi.applyTransaction({ update: data });
          gridApi.redrawRows();
        }

        this.editStrategyNotification(!!uneditedIds, editedItemsData, response, whatIsEdited);
      } else if (response.errors) {
        // Отправляем конверсию в Яндекс.Метрику
        whatIsEdited === WhatIsEditedInStrategy.bid
          ? sendMetricGoal('error_vk_change_bid', 'manage/vk')
          : sendMetricGoal('error_vk_change_budget', 'manage/vk');

        // ловим ошибку от нашего апи
        response.errors.forEach((error: MtRespError) => {
          this.addNotification({
            type: STATUS.ERROR,
            title:
              whatIsEdited === WhatIsEditedInStrategy.bid
                ? 'Ошибка изменения ставки'
                : 'Произошла ошибка при изменении лимита',
            message: error.message,
          });
        });
      }

      return response;
    } catch (error) {
      // ловим ошибку от мт-шного апи
      this.addNotification({
        type: STATUS.ERROR,
        title: NotificationErrorTitle.ERROR,
        message: typeof error === 'string' ? error : 'Произошла ошибка',
      });
      throw error;
    } finally {
      // убираем лоадер у строчек таблицы
      gridApi.applyTransaction({
        update: keysOfAllSiblings.map(el => ({ ...gridApi.getRowNode(el)?.data, cellLoadingName: '' })),
      });
      gridApi.redrawRows();
    }
  };

  editFixedBid = async (requestData: FixBidSaveActionProps, gridApi: GridApi) => {
    // this.setLoadingCells(
    //   requestData.map(el => el.obj_id),
    //   'bid',
    // );
    gridApi.applyTransaction({
      update: requestData.map(el => ({
        ...gridApi.getRowNode(el.obj_id.toString())?.data,
        cellLoadingName: 'bid',
      })),
    });
    gridApi.redrawRows();
    try {
      const response = await this.execRequest<BidResp>(
        httpClient2.post('/api/v1/connect/mt/bid', { data: requestData }),
      );
      // Отправляем конверсию в Яндекс.Метрику
      sendMetricGoal('usage_vk_change_bid', 'manage/vk');
      if (response.command_results?.length && response.command_results[0].success) {
        const requestDataObjects: Record<string, number> = {};

        requestData.forEach(el => (requestDataObjects[el.obj_id] = el.bid));
        const requestDataIds = new Set(Object.keys(requestDataObjects));

        this.data?.forEach(el => {
          requestDataIds.has(el.key.toString()) && (el.bidCurrent = requestDataObjects[el.key.toString()]);
        });

        gridApi.applyTransaction({
          update: [...requestDataIds].map(el => ({
            ...gridApi.getRowNode(el)?.data,
            bidCurrent: requestDataObjects[el.toString()],
          })),
        });

        this.addNotification({
          type: STATUS.SUCCESS,
          title: 'Ставка успешно изменена',
        });
      } else if (response.errors) {
        // ловим ошибку от нашего апи
        response.errors.forEach((error: MtRespError) => {
          this.addNotification({
            type: STATUS.ERROR,
            title: 'Ошибка изменения ставки',
            message: error.message,
          });
          // Отправляем конверсию в Яндекс.Метрику
          sendMetricGoal('error_vk_change_bid', 'manage/vk');
        });
      }

      return response;
    } catch (error) {
      // ловим ошибку от мт-шного апи
      this.addNotification({
        type: STATUS.ERROR,
        title: NotificationErrorTitle.ERROR,
        message: typeof error === 'string' ? error : 'Произошла ошибка',
      });
      throw error;
    } finally {
      gridApi.applyTransaction({
        update: requestData.map(el => ({
          ...gridApi.getRowNode(el.obj_id.toString())?.data,
          cellLoadingName: '',
        })),
      });
      gridApi.redrawRows();
    }
  };

  changeSchedule = async (requestData: ScheduleReq, gridApi: GridApi) => {
    this.data!.forEach(row => {
      if (requestData.ad_plan?.obj_id && row.adPlanId === requestData.ad_plan?.obj_id) {
        gridApi.applyTransaction({ update: [{ ...row, cellLoadingName: 'bid' }] });
        gridApi.redrawRows();
      }
      if (requestData.campaign?.obj_id && row.campaignId === requestData.campaign?.obj_id) {
        gridApi.applyTransaction({ update: [{ ...row, cellLoadingName: 'bid' }] });
        gridApi.redrawRows();
      }
    });

    setTimeout(async () => {
      try {
        const response = await this.execRequest<BidResp>(
          httpClient2.post('/api/v1/connect/mt/view/schedule', requestData),
        );
        // Отправляем конверсию в Яндекс.Метрику
        sendMetricGoal('usage_vk_change_dates', 'manage/vk');

        if (response.command_results?.length && response.command_results[0].success) {
          this.data &&
            this.data.forEach(row => {
              if (row.campaignId && row.campaignId === requestData.campaign?.obj_id) {
                row.campaignStart = requestData.campaign?.date_start
                  ? formatISO(new Date(requestData.campaign?.date_start))
                  : requestData.campaign?.date_start;
                row.campaignStop = requestData.campaign?.date_end
                  ? formatISO(new Date(requestData.campaign?.date_end))
                  : requestData.campaign?.date_end;
                gridApi.applyTransaction({ update: [row] });
              }

              if (row.adPlanId === requestData.ad_plan?.obj_id) {
                row.adPlanStart = requestData.ad_plan?.date_start
                  ? formatISO(new Date(requestData.ad_plan?.date_start))
                  : row.requestData.ad_plan?.date_start;
                row.adPlanStop = requestData.ad_plan?.date_end
                  ? formatISO(new Date(requestData.ad_plan?.date_end))
                  : requestData.ad_plan?.date_end;
                gridApi.applyTransaction({ update: [row] });
              }
            });

          this.addNotification({
            type: STATUS.SUCCESS,
            title: 'Расписание успешно изменено',
          });
        }
        if (response.errors) {
          this.addNotification({
            type: STATUS.ERROR,
            title: response.errors[0].message,
          });
        }
      } catch (error) {
        this.addNotification({
          type: STATUS.ERROR,
          title: NotificationErrorTitle.ERROR,
          message: 'Ошибка изменения расписания',
        });
        // Отправляем конверсию в Яндекс.Метрику
        sendMetricGoal('error_vk_change_dates', 'manage/vk');
      } finally {
        this.data!.forEach(row => {
          if (requestData.ad_plan?.obj_id && row.adPlanId === requestData.ad_plan?.obj_id) {
            gridApi.applyTransaction({ update: [{ ...row, cellLoadingName: '' }] });
            gridApi.redrawRows();
          }
          if (requestData.campaign?.obj_id && row.campaignId === requestData.campaign?.obj_id) {
            gridApi.applyTransaction({ update: [{ ...row, cellLoadingName: '' }] });
            gridApi.redrawRows();
          }
        });
      }
    }, 350);
  };

  getStat = async (currentTabName: ManageVkTabNameEnum, wsDictionary: TWsDictionary) => {
    this.startLoading();
    runInAction(() => (this.errorTable = false));

    !this.metricsVK?.length &&
      (await this.getMetricsVK().catch(err => {
        // this.stopLoading();
        this.onLoadError(err);
        throw new Error(err);
      }));

    let reqPath = '';
    let reqPathStat = '';
    const getStatus = this.filterStatuses?.map(status => {
      if (status === StatusEnumVK.active) {
        return 1;
      }
      if (status === StatusEnumVK.blocked) {
        return 0;
      }
      if (status === StatusEnumVK.deleted) {
        return 2;
      }
    });

    const requestConfig = {
      status: getStatus?.length ? getStatus : [0, 1, 2],
      cols: [] as Pick<TManageVKDataRequest, 'cols'>,
    } as TManageVKDataRequest;

    if (typeof this.dateOptions === 'string') {
      requestConfig.relative_period = this.dateOptions;
    } else {
      if (this.dateOptions?.startDate && this.dateOptions?.endDate) {
        requestConfig.date_from = moment(this.dateOptions?.startDate).format('YYYY-MM-DD');
        requestConfig.date_to = moment(this.dateOptions?.endDate).format('YYYY-MM-DD');
      }
    }

    const checkedMetricsVK = localStorage.getItem(STORAGE_ITEM.localStorage.METRICSVK);
    checkedMetricsVK &&
      this.metricsVK &&
      this.metricsVK.forEach(group =>
        group.fields.forEach(
          metric =>
            JSON.parse(checkedMetricsVK).includes(metric.path) &&
            !!metric.obj_types.filter(objType => objType === objTypes[currentTabName]).length &&
            metric.path !== FAST_STAT.SatisticsFast72 &&
            metric.path !== FAST_STAT.SatisticsFast1 &&
            requestConfig.cols?.push(metric.path),
        ),
      );

    if (currentTabName === ManageVkTabNameEnum.ACCOUNTS) {
      reqPath = '/api/v1/core/accounts';

      // типизировать реквест под каждую схему так и не смог, поэтому костыль
      delete requestConfig.status;
    }

    if (currentTabName === ManageVkTabNameEnum.ADPLANS) {
      reqPath = '/api/v1/core/mt/ad_plans';
      reqPathStat = '/api/v1/core/mt/ad_plans/additional';
      this.selectedClientsIds.length && (requestConfig.client_ids = this.selectedClientsIds);
    }

    if (currentTabName === ManageVkTabNameEnum.ADGROUPS) {
      reqPath = '/api/v1/core/mt/ad_groups';

      this.selectedClientsIds.length && (requestConfig.client_ids = this.selectedClientsIds);
      this.selectedAdPlansIds.length && (requestConfig.ad_plan_ids = this.selectedAdPlansIds);
    }

    if (currentTabName === ManageVkTabNameEnum.ADS) {
      reqPath = '/api/v1/core/mt/ads';
      this.selectedClientsIds.length && (requestConfig.client_ids = this.selectedClientsIds);
      this.selectedAdPlansIds.length && (requestConfig.ad_plan_ids = this.selectedAdPlansIds);
      // @ts-ignore TODO
      this.selectedAdGroupsIds.length && (requestConfig.campaign_ids = this.selectedAdGroupsIds);
    }

    await this.execRequest<string>(httpClient.post(reqPath, requestConfig))
      .then(async resp => {
        this.configRequestStat = requestConfig;

        const statistic = parseStatistics(resp);
        const columns = statistic[0].map(item => parseStringToCamelCase(item));

        const values = statistic.filter((_, index) => index !== 0);

        const data = values.length
          ? parseToData(columns, values, this.dictionary || {}, currentTabName, wsDictionary)
          : [];

        const group = this.metricsForGrouping[objTypes[currentTabName]].filter(el => el.checked);

        transaction(() => {
          runInAction(() => {
            this.data =
              data && group.length
                ? grouping(group[0], data, this.metricsVK || [], mainTabData[objTypes[currentTabName]] as string[])
                : data;
          });
        });
      })
      .catch(this.onLoadError)
      .finally(() => {
        this.stopLoading();
        this.checkTranslation(currentTabName);
      });
  };
  // ===========excel================

  downloadExcelStat = async (currentTabName: ManageVkTabNameEnum, setLoading: Dispatch<SetStateAction<boolean>>) => {
    let reqPath = '';
    const controller = new AbortController();
    const keyNotification = Date.now();
    const body = { ...this.configRequestStat, xlsx: 1 };

    this.addNotification({
      title: NotificationTitle.DOWNLOAD_REPORT,
      type: STATUS.LOADING,
      message: 'Идет подготовка отчета...',
      key: keyNotification,
      canceledDownloadExcel: e => {
        e.stopPropagation();
        controller.abort();
        setLoading(false);
      },
    });

    if (currentTabName === ManageVkTabNameEnum.ACCOUNTS) {
      reqPath = '/api/v1/core/accounts';

      this.selectedClientsIds.length && (body.client_ids = this.selectedClientsIds);
    }
    if (currentTabName === ManageVkTabNameEnum.ADPLANS) {
      reqPath = '/api/v1/core/mt/ad_plans';
      this.selectedAdPlansIds.length && (body.ad_plan_ids = this.selectedAdPlansIds);
    }

    if (currentTabName === ManageVkTabNameEnum.ADGROUPS) {
      reqPath = '/api/v1/core/mt/ad_groups';

      this.selectedAdGroupsIds.length && (body.ad_group_ids = this.selectedAdGroupsIds);
    }

    if (currentTabName === ManageVkTabNameEnum.ADS) {
      reqPath = '/api/v1/core/mt/ads';

      this.selectedAdIds.length && (body.ad_ids = this.selectedAdIds);
    }

    setTimeout(async () => {
      try {
        let name = '';
        const blob = await this.execRequest(
          httpClient
            .post(reqPath, body, {
              responseType: 'blob',
              signal: controller.signal,
            })
            .then((res: any) => {
              // prettier-ignore
              name = decodeURIComponent(escape(atob(res.headers.get('content-disposition').split('"')[1])));
              return res;
            }),
        );
        downloadFile(blob, name);
        // Удаление нотификатора
        this.deleteNotification(keyNotification);
        toast.dismiss(keyNotification);
        // Отправляем конверсию в Яндекс.Метрику
        sendMetricGoal('usage_vk_excel_download', 'manage/vk');
      } catch (e) {
        if (!controller.signal?.aborted) {
          this.addNotification({
            type: STATUS.ERROR,
            title: NotificationErrorTitle.LOAD_ERROR,
            message: <DownloadExcelError />,
          });
          // Отправляем конверсию в Яндекс.Метрику
          sendMetricGoal('error_vk_excel_download', 'manage/vk');
        }
      } finally {
        setLoading(false);
      }
    }, 1500);
  };

  private onLoadError = (error: any) => {
    runInAction(() => {
      this.data = [];
      this.errorTable = !!error;
    });
  };

  // ===========change status===========
  changeManageVkStatus = async (
    ids: number[],
    status: Exclude<MTStatus, 'recovery'>,
    tabName: ManageVkTabNameEnum,
    gridApi: GridApi,
  ) => {
    const req: ChangeStatusIn = { ids, status: status };
    let path = '';
    if (tabName === ManageVkTabNameEnum.ADPLANS) path = '/api/v2/connect/mt/ad_plan/status';
    if (tabName === ManageVkTabNameEnum.ADGROUPS) path = '/api/v2/connect/mt/ad_group/status';
    if (tabName === ManageVkTabNameEnum.ADS) path = '/api/v2/connect/mt/banner/status';

    const rows = ids.map(id => gridApi.getRowNode(id.toString())?.data);
    gridApi.applyTransaction({ update: rows.map(el => ({ ...el, cellLoadingName: 'status' })) });
    gridApi.redrawRows();

    await this.execRequest<BidResp>(httpClient2.post(path, req))
      .then(response => {
        if (response) {
          this.changeStatusSuccess(ids, status, response, gridApi);
        } else {
          throw new Error('Сервер вернул ошибку, попробуйте позднее');
        }
      })
      .catch((error: string) => this.changeStatusError(error))
      .finally(() => {
        const rows = ids.map(id => gridApi.getRowNode(id.toString())?.data);
        gridApi.applyTransaction({ update: rows.map(el => ({ ...el, cellLoadingName: '' })) });
        gridApi.redrawRows();
      });
  };

  private changeStatusSuccess = (
    ids: number[],
    status: Exclude<MTStatus, 'recovery'>,
    resp: BidResp,
    gridApi: GridApi,
  ) => {
    if (resp.errors?.length) {
      this.addNotification({
        type: STATUS.ERROR,
        title: 'Ошибка изменения статуса',
        message: resp.errors.length > 1 ? resp.errors.map(e => e.obj_id).join() : resp.errors[0].message,
      });
      // Отправляем конверсию в Яндекс.Метрику
      sendMetricGoal('error_vk_change_status', 'manage/vk');
    } else {
      this.addNotification({
        type: STATUS.SUCCESS,
        title: 'Статус успешно изменен',
      });

      this.data!.forEach(el =>
        ids.includes(el.key) ? { ...el, status: MTManageVkStatusEnum[status], cellLoadingName: '' } : el,
      );
      gridApi.applyTransaction({
        update: ids.map(id => ({
          ...gridApi.getRowNode(id.toString())?.data,
          status: MTManageVkStatusEnum[status],
        })),
      });
    }
  };

  private changeStatusError = (error: string) => {
    this.addNotification({
      type: 'error',
      title: 'Ошибка изменения статуса',
      message: error,
    });
  };

  private editStrategyNotification = (
    someItemsWereNotEdited: boolean,
    editedItemsData: StrategyBidSaveActionProps,
    response: BidResp,
    whatIsEdited: WhatIsEditedInStrategy,
  ) => {
    // уведомления появляются, если не все рекламные объекты успешно отредактировались
    if (someItemsWereNotEdited) {
      // уведомление с массивом успешно отредактированных id
      this.addNotification({
        type: STATUS.SUCCESS,
        title: `Ставка успешно изменена у ${editedItemsData.length} ${getNumeral(editedItemsData.length, [
          'рекламного объекта',
          'рекламных объектов',
          'рекламных объектов',
        ])}`,
        message: `${JSON.stringify(editedItemsData.map(i => i.obj_id))}`,
      });

      const errorMessages = response.errors?.map(i => i.message).join(', ');

      // уведомления для каждого неотредактированного рекламного объекта:
      // TODO: разобраться, почему без setTimeout тоасты дублируются
      setTimeout(
        () =>
          response.errors?.length &&
          this.addNotification({
            type: STATUS.ERROR,
            title: 'Ошибка изменения ставки для следующих рекламных объектов: ',
            message: `${JSON.stringify(response.errors.map(i => i.obj_id))}

                  ${errorMessages}`,
          }),
        1000,
      );
    } else {
      // Когда всё успешно отредактировалось:
      this.addNotification({
        type: STATUS.SUCCESS,
        title: whatIsEdited === WhatIsEditedInStrategy.bid ? 'Ставка успешно изменена' : 'Бюджет успешно обновлен',
      });
    }
  };

  // grouping

  setGroupingMetrics = (tab: TObjType, path: string, clickedMetric?: TMetric) => {
    runInAction(
      () =>
        (this.metricsForGrouping = {
          ...this.metricsForGrouping,
          [tab]: this.metricsForGrouping[tab].map(metric =>
            metric.path === path ? { ...metric, checked: !metric.checked } : { ...metric, checked: false },
          ),
        }),
    );

    runInAction(() => (this.tableLoading = true));

    setTimeout(() => {
      if (clickedMetric && !clickedMetric.checked && path) {
        if (new Set(Object.keys(this.data![0])).has('orgHierarchy')) {
          const data = this.data!.filter(el => el.orgHierarchy && el.orgHierarchy.length).map(el => {
            const { orgHierarchy, ...data } = el;
            return { ...data };
          });
          runInAction(() => (this.data = grouping(clickedMetric, data || [], this.metricsVK || [], mainTabData[tab])));
          this.setIsGroupDestroyed(true);
        } else {
          runInAction(
            () => (this.data = grouping(clickedMetric, this.data || [], this.metricsVK || [], mainTabData[tab])),
          );
        }
      } else {
        if (new Set(Object.keys(this.data![0])).has('orgHierarchy')) {
          const data = this.data!.filter(el => el.orgHierarchy && el.orgHierarchy.length).map(el => {
            const { orgHierarchy, ...data } = el;
            return { ...data };
          });
          runInAction(() => (this.data = data));
          this.setIsGroupDestroyed(true);
        }
      }
      runInAction(() => (this.tableLoading = false));
    }, 300);
  };

  getAdsSmallPreview = (gridApi: GridApi, nodes: IRowNode[]) => {
    const previewNodes = nodes.filter(el => el.data.previewSmall === 'none');
    const previewKeys = previewNodes.map(({ data }) => data.key);

    if (!previewNodes.length) return;

    gridApi.applyTransaction({
      update: previewNodes.map(({ data }) => ({
        ...data,
        previewSmall: 'loading',
      })),
    });

    this.execRequest<AdsSmallPreviewResp[]>(
      httpClient2.post('/api/v1/core/mt/ads/small_preview', { ad_ids: previewKeys }),
    )
      .then(res => {
        const previewHash: { [key in string]: string } = {};
        res.forEach(e => {
          previewHash[e.ad_id] = e.small_preview;
        });

        gridApi.applyTransaction({
          update: previewNodes.map(({ data }) => {
            if (previewHash.hasOwnProperty(data.key)) {
              return { ...data, [PREVIEW.SMALL]: previewHash[data.key] };
            }
            return data;
          }),
        });

        this.data?.forEach(el => {
          if (previewHash.hasOwnProperty(el.key)) el[PREVIEW.SMALL] = previewHash[el.key];
        });
      })
      .catch(() => {
        gridApi.applyTransaction({
          update: previewNodes.map(({ data }) => {
            if (previewKeys.includes(Number(data.key))) {
              return { ...data, [PREVIEW.SMALL]: '' };
            }
            return data;
          }),
        });

        this.data?.forEach(el => {
          if (previewKeys.includes(Number(el.key))) el[PREVIEW.SMALL] = '';
        });
      });
  };

  editName = (
    req: NameResp,
    gridApi: GridApi,
    data: any,
    setLoading: (load: boolean) => void,
    setError: (error: string) => void,
    colId?: string,
  ) => {
    setTimeout(async () => {
      if (colId) {
        let name = '';
        let path = '';
        if (colId === 'adPlanName') {
          path = '/api/v2/connect/mt/ad_plan/name';
          name = 'adPlanName';
        }

        if (colId === 'campaignName') {
          path = '/api/v2/connect/mt/ad_group/name';
          name = 'campaignName';
        }

        if (colId === 'adName') {
          path = '/api/v2/connect/mt/banner/name';
          name = 'adName';
        }

        await this.execRequest<NameResp>(httpClient2.post(path, [req]))
          .then(response => {
            // Отправляем конверсию в Яндекс.Метрику
            sendMetricGoal('usage_vk_change_name', 'manage/vk');
            if (response) {
              gridApi.applyTransaction({ update: [{ ...data, [name]: req.name }] });
              gridApi.redrawRows();
            } else {
              throw new Error(response);
            }
          })
          .catch(error => {
            setError(error);
            // Отправляем конверсию в Яндекс.Метрику
            sendMetricGoal('error_vk_change_name', 'manage/vk');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, 500);
  };

  private checkTranslation = (currentTabName: ManageVkTabNameEnum) => {
    // Проверка словаря причин трансляции => ошибка в Sentry
    this.data?.forEach(el => {
      Array.isArray(el.issues) &&
        el.issues.forEach((issue: IssuesType) => {
          if (!(issue.code in TRANSLATION)) {
            captureError({
              tags: {
                ISSUE: 'ISSUE',
              },
              extras: {
                'Issue Message': issue.message,
                'Issue Code': issue.code,
                [TabNameToReq[objTypes[currentTabName]]]: el[TabNameToReq[objTypes[currentTabName]]],
              },
              captureMessage: { message: 'ISSUE not at the dictionary of issues !', level: 'warning' },
            });
          }
        });
    });
  };

  getAdditional = (
    gridApi: GridApi,
    nodes: IRowNode[],
    idType: typeof FAST_STAT_IDS[keyof typeof FAST_STAT_IDS],
    currentTabName: ManageVkTabNameEnum,
  ) => {
    let reqPathStat = '';
    const requestBody = {};

    if (currentTabName === ManageVkTabNameEnum.ACCOUNTS) {
      reqPathStat = '/api/v1/core/mt/accounts/additional';
    }

    if (currentTabName === ManageVkTabNameEnum.ADPLANS) {
      reqPathStat = '/api/v1/core/mt/ad_plans/additional';
    }

    if (currentTabName === ManageVkTabNameEnum.ADGROUPS) {
      reqPathStat = '/api/v1/core/mt/ad_groups/additional';
    }

    if (currentTabName === ManageVkTabNameEnum.ADS) {
      reqPathStat = '/api/v1/core/mt/ads/additional';
    }

    const statisticsTodayRender = nodes.filter(el => el.data.statisticsSpentToday === 0);

    const statKeys = statisticsTodayRender.map(({ data }) => data.key);

    // @ts-ignore
    requestBody[TabNameToReq[objTypes[currentTabName]]] = statKeys;

    if (statisticsTodayRender) {
      gridApi.applyTransaction({
        update: statisticsTodayRender.map(({ data }) => ({
          ...data,
          statisticsSpentToday: 'loading',
        })),
      });
    }

    statKeys.length &&
      this.execRequest<AccountAdditionalItemResp>(httpClient2.post(reqPathStat, requestBody))
        .then(res => {
          const resFast: { [p: string]: any } = res.reduce((acc, el) => {
            const getRandomArbitrary = (min: number, max: number) => {
              return Math.random() * (max - min) + min;
            };
            // @ts-ignore
            acc[el[idType]] = {
              statistics_spent_today:
                el.statistics_spent_today ?? (!isUrlProd && !isUrlPreProd ? getRandomArbitrary(0, 1000) : ''),
            };

            return acc;
          }, {});

          gridApi.applyTransaction({
            update: statisticsTodayRender.map(({ data }) => ({
              ...data,
              statisticsSpentToday: resFast[data.key].statistics_spent_today,
            })),
          });

          runInAction(() =>
            this.data?.forEach(el => {
              if (resFast[el.key]) {
                el.statisticsSpentToday = resFast[el.key].statistics_spent_today;
              }
            }),
          );
        })
        .catch(() => {
          gridApi.applyTransaction({
            update: statisticsTodayRender.map(({ data }) => ({
              ...data,
              statisticsSpentToday: '',
            })),
          });
          this.data?.forEach(el => {
            if (statisticsTodayRender.includes(el.key)) {
              el.statisticsSpentToday = '';
            }
          });
        });
  };

  resetStore = () =>
    runInAction(() => {
      this.filter = { inputValue: '' };
      this.data = [];
      this.tableLoading = false;
      this.metricsVK = undefined;
      this.drawerAction = undefined;
      this.manageVKTabs = [3, 5];
      this.dictionary = undefined;
      this.drawerVisible = false;
      this.filterValue = undefined;
      this.filterStatuses = [StatusEnumVK.active, StatusEnumVK.blocked];
      this.fast72Checked = checkFast72();
      this.errorTable = false;
      this.metricsFilterValue = '';
      this.dateOptions = {} as DOptions;
      this.date = {
        storeLabel: MENU_ITEM_LABEL.All_TIME,
        storeEndDate: Date.now(),
        storeStartDate: +new Date('2012, 01, 01'),
      } as DateOptions;
      this.deleteAction = undefined;
      this.currentTab = undefined;
      this.configRequestStat = {};
      this.selectedAdIds = [];
      this.selectedAdPlansIds = [];
      this.selectedClientsIds = [];
      this.selectedAdGroupsIds = [];
      this.isLoading = true;
      this.metricsForGrouping = {} as TMetricsForGrouping;
      this.isGroupDestroyed = false;
    });
}
