import { text } from 'node:stream/consumers';
import { CheckIcon, CopyTextIcon, EditIcon, Typography } from '@plarin/design';
import { Button, Column, MainTooltip, GridApi, Popover, TextArea, TextAreaErrors } from '@plarin/inputs';
import clsx from 'clsx';
import { set } from 'date-fns';
import React, { useRef, useState } from 'react';
import { useAppDataStore } from '../app-data-provider';
import classes from './style.module.scss';

interface Props {
  data: any;
  gridApi: GridApi;
  name: string;
  column: Column;
  setIsHoverCell: (hover: boolean) => void;
  disableEditName?: boolean;
}

const getTitle = (colId?: string) => {
  switch (colId) {
    case 'adPlanName':
      return 'Название кампании';
    case 'campaignName':
      return 'Название группы объявлений';
    case 'adName':
      return 'Название объявления';
    default:
      return '';
  }
};

const getDefaultValue = (data: any, colId?: string) => {
  switch (colId) {
    case 'adPlanName':
      return data.adPlanName;
    case 'campaignName':
      return data.campaignName;
    case 'adName':
      return data.adName;
    default:
      return '';
  }
};

const getId = (data: any, colId?: string) => {
  switch (colId) {
    case 'adPlanName':
      return Number(data.adPlanId);
    case 'campaignName':
      return Number(data.campaignId);
    case 'adName':
      return Number(data.adId);
    default:
      return 0;
  }
};

export const ActionsName = ({ name, data, gridApi, column, setIsHoverCell, disableEditName }: Props) => {
  const {
    manageVK: { editName },
  } = useAppDataStore();

  const [isCopiedName, setIsCopiedName] = useState(false);
  const [isHoverIconCopy, setHoverIsIconCopy] = useState(false);

  const [isOpenEditMenu, setIsOpenEditMenu] = useState(false);
  const [isHoverEditIcon, setHoverIsEditIcon] = useState(false);

  const [textValue, setTextValue] = useState<string>(getDefaultValue(data, column.colId) || '');

  const [isLoading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');

  const anchorRef = useRef<HTMLDivElement>(null);

  const onCopyName = (ref: HTMLDivElement) => {
    if (!ref) return;
    ref.onclick = event => {
      event.stopPropagation();
      event.view?.navigator.clipboard.writeText(name).then(() => setIsCopiedName(true));
    };
  };

  const onEditName = (ref: HTMLDivElement) => {
    if (!ref) return;
    ref.onclick = event => {
      event.stopPropagation();
      setIsOpenEditMenu(true);
    };
  };

  const onCloseEditMenu = () => {
    if (!isLoading) {
      setIsOpenEditMenu(false);
      setIsHoverCell(false);
    }
  };

  const onChangeName = () => {
    if (!isLoading) {
      setLoading(true);
      const req = { object_id: getId(data, column.colId), name: textValue };
      editName(req, gridApi, data, setLoading, setErrorText, column.colId);
    }
  };

  return (
    <>
      <div ref={anchorRef} className={classes.actionsName}>
        {!disableEditName && (
          <div
            ref={onEditName}
            onMouseEnter={() => setHoverIsEditIcon(true)}
            onMouseLeave={() => setHoverIsEditIcon(false)}
          >
            <MainTooltip
              tooltipMessage="Редактировать название"
              isVisible={true}
              component="div"
              placement="bottom-start"
              componentFeature="textEllips"
              textAlign="left"
              followCursor={true}
            >
              <EditIcon size={16} color={isHoverEditIcon ? 'primary60' : 'main'} className={classes.icon} />
            </MainTooltip>
          </div>
        )}
        <div
          ref={onCopyName}
          onMouseEnter={() => setHoverIsIconCopy(true)}
          onMouseLeave={() => setHoverIsIconCopy(false)}
        >
          {isCopiedName ? (
            <MainTooltip
              tooltipMessage="Название скопировано"
              isVisible={true}
              component="div"
              placement="bottom-start"
              componentFeature="textEllips"
              textAlign="left"
              followCursor={true}
            >
              <CheckIcon size={16} color="primary60" />
            </MainTooltip>
          ) : (
            <MainTooltip
              tooltipMessage="Копировать название"
              isVisible={true}
              component="div"
              placement="bottom-start"
              componentFeature="textEllips"
              textAlign="left"
              followCursor={true}
            >
              <CopyTextIcon size={16} color={isHoverIconCopy ? 'primary60' : 'main'} className={classes.icon} />
            </MainTooltip>
          )}
        </div>
      </div>
      <Popover
        setIsOpen={onCloseEditMenu}
        anchorEl={anchorRef.current}
        isOpen={isOpenEditMenu}
        classNameWrapper={classes.editNamePopover}
      >
        <>
          <Typography color="TextPrimary" weight={700} size="Main">
            {getTitle(column.colId)}
          </Typography>
          <TextArea
            value={textValue}
            setValue={e => {
              e.trim().length < 3 ? setErrorText(TextAreaErrors.minLength) : setErrorText('');
              setTextValue(e);
            }}
            maxLengthValue={255}
            maxRows={10}
            className={classes.textAreaContainer}
            classNameRoot={clsx(classes.textAreaRoot, errorText && classes.textAreaRootDanger)}
            isTrimMaxLength
            disabled={isLoading}
            onBlurAction={() => {
              setTextValue(textValue.trim());
            }}
          />
          <div className={classes.count}>
            <>
              <Typography size="TableRowSubHeader" color={errorText ? 'Danger' : 'TextSecondary'}>
                {errorText}
              </Typography>
              <Typography size="TableRowSubHeader" color={errorText ? 'Danger' : 'TextSecondary'}>
                {textValue.length} из 255
              </Typography>
            </>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              variant="filled"
              color="primary"
              onClick={onChangeName}
              disabled={textValue.trim().length < 3 || textValue === getDefaultValue(data, column.colId)}
              loading={isLoading}
            >
              <Typography weight={600} size="Main">
                Сохранить
              </Typography>
            </Button>
            <Button variant="outlined" size="small" onClick={onCloseEditMenu} disabled={isLoading}>
              <Typography weight={600} size="Main">
                Отменить
              </Typography>
            </Button>
          </div>
        </>
      </Popover>
    </>
  );
};
